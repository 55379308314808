// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #013374, secondary: #a3d8f6, tertiary: #242f46, neutral: #eff5fa
$_palettes: (
  primary: (
    0: #000000,
    10: #001a42,
    20: #002e6a,
    25: #0c397a,
    30: #1e4586,
    35: #2c5193,
    40: #3a5da0,
    50: #5476ba,
    60: #6e90d6,
    70: #89abf3,
    80: #aec6ff,
    90: #d8e2ff,
    95: #edf0ff,
    98: #f9f9ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #001e2b,
    20: #003548,
    25: #004057,
    30: #0b4c65,
    35: #1e5872,
    40: #2d647e,
    50: #487d98,
    60: #6397b3,
    70: #7eb2cf,
    80: #99ceeb,
    90: #c2e8ff,
    95: #e2f3ff,
    98: #f5faff,
    99: #fbfcff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #101b31,
    20: #253047,
    25: #303b53,
    30: #3c475f,
    35: #47526b,
    40: #535e78,
    50: #6c7791,
    60: #8691ac,
    70: #a0abc7,
    80: #bbc6e4,
    90: #d8e2ff,
    95: #edf0ff,
    98: #f9f9ff,
    99: #fefbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    4: #090b0d,
    6: #0d1113,
    10: #161c20,
    12: #1a2024,
    17: #252b2f,
    20: #2b3135,
    22: #2f3539,
    24: #343a3e,
    25: #363c40,
    30: #41484c,
    35: #4d5357,
    40: #595f64,
    50: #71787c,
    60: #8b9296,
    70: #a6acb1,
    80: #c1c7cc,
    87: #d5dbe0,
    90: #dde3e8,
    92: #e3e9ee,
    94: #e8eef3,
    95: #ebf1f6,
    96: #eef4f9,
    98: #f4faff,
    99: #fafcff,
    100: #ffffff,
  ),
  neutral-variant: (
    0: #000000,
    10: #181b24,
    20: #2d3039,
    25: #383b45,
    30: #434750,
    35: #4f525c,
    40: #5b5e68,
    50: #747781,
    60: #8d909b,
    70: #a8abb6,
    80: #c4c6d2,
    90: #e0e2ee,
    95: #eef0fd,
    98: #f9f9ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));
$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));

html {
  color-scheme: normal;
  @include mat.theme(
    (
      color: mat.$violet-palette,
      typography: Roboto,
      density: 0,
    )
  );
}
