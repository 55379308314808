button {
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 8px;
  transition: all 0.25s ease;
  font-size: 19px;
  width: auto;
  height: 48px;
  box-shadow: none;
  font-family: 'Thieme', sans-serif;
  text-wrap: nowrap;

  &.small {
    padding: 4px 12px;
    height: 40px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 425;
    
  svg {
    margin: -0.25rem;
    gap: 0.5rem;
    padding-bottom: 0.0625rem;
    width: 1.25rem;
    height: 1.25rem; 
    }
  }

  &.x-small {
    padding: 2px 10px;
    height: 28px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
  }

  &.btn-primary {
    color: var(--text-heading-primary);
    background: var(--color-light-blue-400);
    border: none;
    font-weight: 400;
  }

  &.btn-primary:hover {
    color: var(--text-heading-primary);
    background: var(--color-light-blue-450);
  }

  &.btn-primary:disabled {
    background: var(--color-grey-200);
    color: var(--color-grey-400);
    cursor: auto;
  }

  &.btn-primary:active:not(:disabled) {
    background-color: var(--text-heading-primary);
    color: var(--white-100);
  }

  &.btn-secondary {
    color: var(--text-heading-primary);
    font-weight: 400;
    border: 1px solid var(--text-heading-primary);
    border-color: var(--text-heading-primary);
    background: var(--white-100);
  }

  &.btn-secondary:hover {
    color: var(--text-heading-primary);
    background: var(--color-light-blue-400);
  }

  &.btn-secondary:disabled {
    border: 1px solid var(--color-grey-400);
    background: var(--white-100);
    color: var(--color-grey-400);
    cursor: auto;
  }

  &.btn-secondary:active:not(:disabled) {
    background-color: var(--text-heading-primary);
    color: var(--white-100);
    :first-child {
      fill: var(--white-100);
    }
  }

  &.btn-tertiary {
    color: var(--text-heading-primary);
    background: var(--white-100);
    border: none;
  }

  &.btn-tertiary:hover {
    background: var(--color-light-blue-400);
  }

  &.btn-tertiary:disabled {
    background: var(--white-100);
    color: var(--color-grey-400);
    cursor: auto;
  }

  &.btn-tertiary:active:not(:disabled) {
    background: var(--color-blue-200);
    border: none;
  }

  &.btn-warning {
    color: var(--white-100);
    background: var(--color-red-600);
    border: none;
  }

  &.btn-warning:hover {
    color: var(--white-100);
    background: var(--color-red-700);
  }

  &.btn-warning:disabled {
    background: var(--color-grey-200);
    color: var(--color-grey-400);
    cursor: auto;
  }

  &.btn-warning:active:not(:disabled) {
    background-color: var(--color-red-800);
    color: var(--white-100);
  }

  &.btn-plain {
    all: unset;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:disabled {
      color: var(--color-grey-400);
      cursor: auto;
    }
  }

  &.width-100 {
    width: 100% !important;
  }

  &:focus-visible {
    transition: outline 0.1s;
    outline: 4px solid var(--outline-focus);
  }

  &.small {
    padding: 4px 12px 4px 12px;
    height: 40px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 425;
    line-height: 24px;
  }

  &.x-small {
    padding: 2px 10px 2px 10px;
    height: 28px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
  }
}
